import { cn } from '@/ui/lib/utils'

export const Line = (props: { isDashed?: boolean; className?: string }) => {
  return (
    <div
      className={cn(
        'w-full border-b',
        props.isDashed ? 'border-dashed border-slate-300' : 'border-cyan-300',
        props.className
      )}
    />
  )
}
